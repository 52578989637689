// import $ from 'jquery';
import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import VanillaTilt from 'vanilla-tilt';
import remodal from 'remodal';
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);

const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};

const positiongui = document.querySelectorAll(".js-position-gui")
if (positiongui.length!=0) {
  document.body.insertAdjacentHTML('beforeend',"<div id='gui' style='position: absolute;right: 30px;top: 30px;background: rgba(0, 0, 0, .5);padding: 20px;'></div>")
}
positiongui.forEach((guiTarget,i)=>{
  const className = "js-gui-slide"+i;
  const positon = guiTarget.getAttribute('data-position').split(',');
  positon.forEach((pos)=>{
    gui.insertAdjacentHTML('beforeend',pos+':<input type="range" data-type="'+pos+'" class="js-range '+className+'" min="-500" max="500" value="0" step="0.1" /><br>')
  })
  gui.insertAdjacentHTML('beforeend','<br>')
  guiTarget.style.position ="absolute";
  const inputRanges = document.querySelectorAll("."+className);
  inputRanges.forEach((inputRange)=>{
    inputRange.addEventListener('input', (e)=>{
      const self = e.target;
      if (self.getAttribute('data-type')==="left") {
        guiTarget.style.left = (self.value*0.1)+'rem';
      } else if(self.getAttribute('data-type')==="top") {
        guiTarget.style.top = (self.value*0.1)+'rem';
      } else if (self.getAttribute('data-type')==="right") {
        guiTarget.style.right = (self.value*0.1)+'rem';
      } else if(self.getAttribute('data-type')==="bottom") {
        guiTarget.style.bottom = (self.value*0.1)+'rem';
      }
    })
  })
})